import { styled, Box, Avatar as MuiAvatar, Button } from "@mui/material";

export const Container = styled(Box)(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "250px",
  minWidth: "250px",
  height: "100%",
  padding: "32px 10px 10px",
  backgroundColor: "#ffffff",
  overflow: "auto",
}));

export const Avatar = styled(MuiAvatar)({
  width: "32px",
  height: "32px",
  borderRadius: "10px",
  border: "1px solid #202124",
});

export const MenuItem = styled(Box, {
  shouldForwardProp: (props: any) => props !== "isActive",
})(({ isActive }: { isActive: boolean; }) => ({
  with: "100%",
  height: "44px",
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
  gap: "20px",
  fontSize: "14px",
  fontWeight: "500",
  color: isActive ? "#ffffff" : "#7A86A1",
  backgroundColor: isActive ? "#78C8F3" : "inherit",
  borderRadius: "8px",
  textDecoration: "none",
  padding: "28px 30px",
  cursor: "pointer",
  marginBottom: "6px",
  "&:hover": {
    backgroundColor: !isActive && "#78C8F320",
  },
}));

export const SignOutButton = styled(Button)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  color: "#202124"
}))
