export enum UrlSlugType {
  // Public url
  LOGIN = "/op-admin/login",
  FORGOT_PASSWORD = "/op-admin/forgot-password",
  RESET_PASSWORD = "/op-admin/reset-password",
  SIGNUP_USER = "/sign-up",

  // Admin Url
  Home = "/home",
  INITIAL_USER = "/init-user",
  STORED_USER = "/pending-user",
  PENDING_APPROVAL = "/pending-approval",
  APPROVED_USER = "/approved-user",
  SINGLE_USER = "/op-admin/user/:id",
}
