import { ExtFile } from "@dropzone-ui/react";
import { Base64State } from "../types";
import dayjs, { Dayjs } from "dayjs";

export const objectToSearchParams = (obj: any, ignore: string[] = []) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!ignore.includes(key)) {
        params.append(key, obj[key]);
      }
    }
  }

  return params.toString();
};

export function capitalizeWords(value: string) {
  return value
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function base64ToFile(base64Data: any) {
  const contentType = base64Data.match(/^data:([^;]+);base64,/)[1];
  const base64WithoutPrefix = base64Data.split(",")[1];
  const bytes = atob(base64WithoutPrefix);
  const array = new Uint8Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    array[i] = bytes.charCodeAt(i);
  }
  const blob = new Blob([array], { type: contentType });
  const file = new File([blob], "File name", { type: contentType });
  return { ...file, file: file, valid: true } as ExtFile;
}

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

export const handleBase64 = (
  file: File | undefined,
  setBase64Callback: any,
  fieldName: string,
  base64: Base64State
) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    if (file) {
      const base64Result = reader.result as string;
      setBase64Callback({ ...base64, [fieldName]: base64Result });
    }
  };

  if (file) {
    reader.readAsDataURL(file);
  } else {
    const base64Store = base64;
    delete base64Store[fieldName];
    setBase64Callback({ ...base64Store });
  }
};

export function getImageTypeFromBase64(base64String: string) {
  // Regular expression to match the data URL scheme header
  const regex = /^data:(image\/\w+);base64,/;

  const match = base64String.match(regex);

  if (match) {
    return match[1]; // Return the MIME type (e.g., "image/png")
  } else {
    return null; // Return null if the MIME type could not be determined
  }
}
export function extractBase64Data(dataURI: string) {
  // Validate the input to ensure it's a Data URI
  if (typeof dataURI !== "string" || !dataURI.startsWith("data:")) {
    throw new Error("Invalid Data URI");
  }

  // Split the string by the comma
  const parts = dataURI.split(",");

  // Ensure that we have both parts
  if (parts.length !== 2) {
    throw new Error("Invalid Data URI format");
  }

  // Return the Base64 encoded data part
  return parts[1];
}

export function formatToCSEDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns month from 0 to 11
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
}

export function convertCSEDateFormatToDayJsDate(dateStr: string): Dayjs {
  const jsFormatDate = dateStr.replace(/\//g, "-");
  return dayjs(jsFormatDate);
}
