import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";
import { objectToSearchParams } from '../utils/helpers/common';

export const publicServiceApi = createApi({
  reducerPath: "publicService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getBanks: builder.query({
      query: ({ data }) => ApiSlug.GET_BANKS,
      providesTags: ["banks"]
    }),
    getBankBranches: builder.query({
      query: ({ data }) => `${ApiSlug.GET_BANK_BRANCHES}?${objectToSearchParams(data)}`,
      providesTags: ["bank_branches"]
    }),
  }),
});

export const {
  useLazyGetBanksQuery,
  useLazyGetBankBranchesQuery
} = publicServiceApi;
