import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { ApiSlug } from "../utils/enums/ApiSlug";
import { logOut, setToken } from "../store/slices/auth.slice";
import Notify from '../components/core/Notify';

const query = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;
    token && headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
  > = async (args: any, api, extraOptions) => {
  let result = await query(args, api, extraOptions);
  
  if (result.error && result.error.status === 406) {
    const refreshResult = await query(
      ApiSlug.REFRESH_TOKEN,
      api,
      extraOptions
    );
    if (refreshResult.data) {
      api.dispatch(setToken((refreshResult.data as any).data?.token));
      result = await query(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  } else if (result.error && result.error.status === 403) {
    Notify.error("You do not have access permission!");
  };

  if (result.meta?.response?.status === 500) {
    // api.dispatch(logOut());
    Notify.error("Opps! servers are experiencing technical difficulties. Please try again later...");
  }

  return result;
};

export default baseQuery;
