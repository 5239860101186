import { createTheme } from "@mui/material";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Poppins:300,400,500,600,700", "sans-serif"],
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#78C8F3",
    },
    secondary: {
      main: "#B895F2",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    h1: {
      fontSize: "28px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "26px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "21px",
      fontWeight: 500,
      lineHeight: "22px",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 600,
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
    },
    overline: {
      fontSize: "10px",
      fontWeight: 300,
      lineHeight: "none",
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: {
          height: "47px",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "600",
          ":hover": {
            color: "#FFF",
          },
        },
        text: {
          height: "unset",
          fontWeight: 500,
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        outlined: {
          border: "1px solid #EBEBEB",
          color: "#7A86A1",
        },
      },
    },
  },
});

export default theme;