import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { ApiSlug } from "../utils/enums/ApiSlug";

interface IResponseData<T> {
  code: string;
  data: T;
}

interface ITitle {
  titleCode: string;
  title: string;
}

interface IDistrict {
  districtCode: string;
  districtName: string;
}

interface ICountry {
  countryCode: string;
  countryName: string;
}

export const cseServiceApi = createApi({
  reducerPath: "cseService",
  baseQuery: baseQuery,
  tagTypes: ["title", "district", "cse-bank", "cse-bank-branch", "country"],
  endpoints: (builder) => ({
    getTitles: builder.query<IResponseData<ITitle[]>, any>({
      query: () => ({
        url: ApiSlug.GET_TITLES,
        method: "GET",
      }),
      providesTags: ["title"],
    }),
    getDistricts: builder.query<IResponseData<IDistrict[]>, any>({
      query: () => ({
        url: "/cse/districts",
        method: "GET",
      }),
      providesTags: ["district"],
    }),
    getBanks: builder.query({
      query: () => ({
        url: "/cse/banks",
        method: "GET",
      }),
      providesTags: ["cse-bank"],
    }),
    getBankBranches: builder.query({
      query: (bankCode) => ({
        url: `/cse/banks/${bankCode}/branches`,
        method: "GET",
      }),
      providesTags: ["cse-bank-branch"],
    }),
    getCountries: builder.query<IResponseData<ICountry[]>, any>({
      query: () => ({
        url: "/cse/countries",
        method: "GET",
      }),
      providesTags: ["country"],
    }),
  }),
});

export const {
  useGetTitlesQuery,
  useGetDistrictsQuery,
  useGetBanksQuery,
  useGetBankBranchesQuery,
  useLazyGetBankBranchesQuery,
  useLazyGetBanksQuery,
  useLazyGetDistrictsQuery,
  useLazyGetTitlesQuery,
  useLazyGetCountriesQuery,
} = cseServiceApi;
