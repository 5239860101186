import React from 'react';
import { SnackbarProvider, useSnackbar, MaterialDesignContent } from 'notistack';
import { styled, Typography, IconButton } from '@mui/material';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success_icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/Error.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/Info.svg';
import CloseIcon from '@mui/icons-material/Close';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent": {
    backgroundColor: '#FFFFFF',
    color: "#000000",
    boxShadow: "0px 9px 10px #0000001A",
    borderRadius: "10px",
    // width: "100%",
    // maxWidth: "288px",
    padding: "10px",
    paddingLeft: "16px"
  }
}));

let snackbarRef: any;

const Notify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  snackbarRef = { enqueueSnackbar, closeSnackbar };

  return null;
};

Notify.success = (message: any) => {
  if (snackbarRef) {
    snackbarRef.enqueueSnackbar(
      <Typography ml={ "14px" } fontSize={ "14px" } fontWeight={ 700 }>
        { message }
      </Typography>,
      {
        variant: 'success',
        action: (key: any) => (
          <IconButton sx={ { mr: "12px" } } size="small" color="inherit" onClick={ () => snackbarRef.closeSnackbar(key) }>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
    );
  }
};

Notify.error = (message: any) => {
  if (snackbarRef) {
    snackbarRef.enqueueSnackbar(
      <Typography ml={ "14px" } fontSize={ "14px" } fontWeight={ 700 }>
        { message }
      </Typography>,
      {
        variant: 'error',
        action: (key: any) => (
          <IconButton sx={ { mr: "12px" } } size="small" color="inherit" onClick={ () => snackbarRef.closeSnackbar(key) }>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      });
  }
};

Notify.warning = (message: any) => {
  if (snackbarRef) {
    snackbarRef.enqueueSnackbar(
      <Typography ml={ "14px" } fontSize={ "14px" } fontWeight={ 700 }>
        { message }
      </Typography>,
      {
        variant: 'warning',
        action: (key: any) => (
          <IconButton sx={ { mr: "12px" } } size="small" color="inherit" onClick={ () => snackbarRef.closeSnackbar(key) }>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      });
  }
};

Notify.info = (message: any) => {
  if (snackbarRef) {
    snackbarRef.enqueueSnackbar(<Typography ml={ "14px" } fontSize={ "14px" } fontWeight={ 700 }>
      { message }
    </Typography>,
      {
        variant: 'info',
        action: (key: any) => (
          <IconButton sx={ { mr: "12px" } } size="small" color="inherit" onClick={ () => snackbarRef.closeSnackbar(key) }>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      });
  }
};

Notify.default = (message: any, title?: String, type?: String, link?: String) => {
  if (snackbarRef) {
    snackbarRef.enqueueSnackbar(
      <div>
        <Typography ml={"14px"} fontSize={"14px"} fontWeight={600}>
          {message}
        </Typography>
        <Typography ml={"14px"} fontSize={"14px"} fontWeight={600}>
          {link}
        </Typography>
      </div>,
      {
        variant: 'default',
        action: (key: any) => (
          <IconButton sx={{ mr: "12px" }} size="small" color="inherit" onClick={() => snackbarRef.closeSnackbar(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      });
  }
};

const Notifier = (props: any) => {
  return (
    <SnackbarProvider
      maxSnack={ 3 }
      anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
      autoHideDuration={ 3000 }
      Components={ {
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
      } }
      iconVariant={ {
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      } }
    >
      <Notify />
      { props.children }
    </SnackbarProvider>
  );
};

export default Notify;
export { Notifier };